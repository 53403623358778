@import url(https://fonts.googleapis.com/css?family=Montserrat:300,500,700&subset=latin-ext);
html,
body,
#root {
	font-family: 'Montserrat', '"Helvetica Neue"', 'Arial', sans-serif;
	margin: 0;
	height: 100vh;
	width: 100%;
	overflow: hidden;
	padding-right: 0!important;
}

